export const TRANSLATIONS_PT = {
    // COMMON
    fieldRequired: "Este campo é obrigatório",
    fieldInvalid: "Este campo é inválido",
    fieldMaxLimit: "São necessários menos de {{limit}} caracteres",
    fieldMaxValueLimit: "O valor deve ser menor que {{limit}}",
    fieldMinValueLimit: "O valor deve ser maior que {{limit}}",
    fieldEmailDuplicated: "E-mail duplicado",
    fieldConfirmPasswordCorrectly: "Confirme a senha corretamente",

    success: "Sucesso",
    warning: "Aviso",
    error: "Erro",

    noPermission: "Você não tem permissão!",
    URLNotFound: "URL não encontrado",
    badRequest: "Pedido ruim",
    apiError: "Erro de API",

    // HEADER
    lightningAddressWarning:
        "Adicione pelo menos um <a href='/profile?_tab=lightning'>endereço Lightning</a> para receber pagamentos",
    myWallet: "Minha carteira",
    myProfile: "Meu perfil",
    withdrawalAccounts: "Contas de retirada",
    signOut: "Sair",

    // AUTH LAYOUT
    allRightReserved: "Todos os direitos reservados.",

    // LOGIN
    email: "E-mail",
    password: "Senha",
    keepMeSignedIn: "Mantenha-me conectado",
    signIn: "Entrar",
    forgetPassword: "Esqueceu a senha",
    stillHaveNoAccount: "Ainda não tem conta?",
    signUp: "Inscrever-se",
    getCodeFromYourEmail: "Obtenha o código do seu e-mail",
    getCodeFromYourAuthenticatorApp: "Obtenha o código do seu aplicativo autenticador",
    enterCodeFromYourEmail: "Digite o código do seu e-mail",
    accountSuspendedError:
        "Esta conta foi suspensa. Entre em contato com o suporte em <a href='mailto:{{email}}' target='_blank'>{{email}}</a>",
    notAuthenticated: "Você não está autenticado, faça login novamente!",

    // REGISTER
    confirmPassword: "Confirme sua senha",
    fullname: "Nome completo",
    alreadyHaveAnAccount: "Já tem uma conta?",
    passwordRule: "São necessários 8 caracteres e pelo menos 1 caractere especial(!@#$&*)",

    // FORGET PASSWORD
    code: "Código",
    sendCode: "Enviar código",
    resetPassword: "Redefinir senha",
    resendCode: "Reenviar código",
    backToSignIn: "Voltar para fazer login",
    verificationCodeSentSuccessfully: "O código de verificação foi enviado com sucesso",

    // VERIFY ACCOUNT
    verify: "Verificar",
    verify_email: "Verificar e-mail",
    verify_phone: "Verifique o telefone",
    verifyDescriptionEmail:
        "Para validar sua conta, enviamos um código de verificação para <b class='color-blue'>{{email}}</b>",
    verifyDescriptionPhone:
        "Para validar sua conta, enviamos um segundo código de verificação para <b class='color-blue'>+{{phone}}</b>",
    verifySucceeded: "Sua conta foi verificada com sucesso.",

    // SIDER
    dashboard: "Painel",
    transactions: "Transações",
    withdrawals: "Retirados",
    shift: "Mudança",
    companies: "Empresas",
    users: "Usuários",
    pos: "POS",

    // DASHBOARD
    total: "Total",
    wallet: "Carteira",
    incoming: "Entrada",
    withdrawal: "Cancelamento",
    consumption: "Consumo",
    monthly: "Por mês",
    daily: "Diário",

    // INVOICES
    invoices: "Faturas",
    newInvoice: "Nova fatura",
    expireAt: "Expira em",
    localCurrency: "Moeda local",
    amountReceived: "Montante recebido",
    paidAt: "Pago em",
    copyInvoiceUrl: "Copiar URL da fatura",
    refresh: "Atualizar",
    noInvoice: "Nenhuma fatura",
    payLightningInvoice: "Pagar fatura Lightning",
    pay: "Pagar",
    createInvoice: "Criar recibo",
    invoiceDetail: "Detalhe da fatura",
    invoiceIsExpired: "A fatura expirou",
    invoiceIsPaid: "A fatura está paga",
    awaitingPayment: "Aguardando pagamento",
    close: "Fechar",
    copy: "Cópia de",

    // TRANSACTIONS
    downloadAsExcel: "Baixar como Excel",
    searchByDescription: "Pesquisar por descrição",
    selectPOSDevice: "Selecione o dispositivo POS",
    all: "Todos",
    startDate: "Data de início",
    finishDate: "Data de término",
    date: "Data",
    company: "Empresa",
    device: "Dispositivo",
    description: "Descrição",
    amount: "Quantia",
    tip: "Dica",
    currency: "Moeda",
    commission: "Comissão",
    noTransaction: "Nenhuma transação",

    // WITHDRAWALS
    withdrawalToBitcoin: "Retirada para Bitcoin",
    withdrawalToLightningAddress: "Retirada para o endereço Lightning",
    withdrawalWithLightningInvoice: "Saque com fatura Lightning",
    lightningAddress: "Endereço Lightning",
    selectLightningAddress: "Selecione o endereço Lightning",
    withdrawalAmountLimitTip:
        "Lembramos que, para sua segurança, o primeiro saque em uma nova conta não pode exceder {{firstAmount}} SATs. O segundo saque nesta nova conta poderá ser feito após 24 horas e por um valor diário máximo de {{dailyAmount}} SATs.",
    lightningWithdrawalInvoiceTip:
        "Esta solicitação de retirada será revisada e aprovada pelo administrador, levará algum tempo, portanto, defina um vencimento de fatura suficiente ao criar uma fatura.",
    request: "Solicitar",
    toBankAccount: "Para conta bancária",
    toBitcoinAccount: "Para conta bitcoin",
    requestWithdrawal: "Solicitar retirada",
    account: "Conta",
    status: "Status",
    manager: "Gerente",
    bank: "Banco",
    bitcoin: "Bitcoin",
    noWithdrawal: "Sem retirada",
    requestWithdrawalToBank: "Solicitar saque ao banco",
    bankAccount: "Conta bancária",
    selectBankAccount: "Selecione a conta bancária",
    shortDescriptionAboutWithdrawal: "Breve descrição sobre a retirada",
    cancel: "Cancelar",
    create: "Criar",
    requestWithdrawalToBitcoin: "Solicitar retirada para bitcoin",
    bitcoinAccount: "Conta bitcoin",
    selectBitcoinAccount: "Selecione a conta bitcoin",
    balanceNotEnoughError: "O saldo da sua carteira não é suficiente para concluir o pagamento",
    withdrawalToLightningAccount: "Retirada para conta relâmpago",
    invoiceUrl: "URL da fatura",
    expiry: "Termo",
    seconds: "Segundos",
    proceedWithdrawal: "Continuar retirada",
    withdrawalStatus_initiated: "Iniciado",
    withdrawalStatus_in_review: "Em revisão",
    withdrawalStatus_in_process: "Em processo",
    withdrawalStatus_finished: "Finalizado",
    withdrawalStatus_rejected: "Rejeitado",
    withdrawalStatusDescription_initiated: "Solicitação de saque é criada, aguardando a análise do gerente",
    withdrawalStatusDescription_in_review: "A solicitação de retirada está sob análise do gerente",
    withdrawalStatusDescription_in_process: "A solicitação de saque está em andamento",
    withdrawalStatusDescription_finished: "O processo de retirada está concluído",
    withdrawalStatusDescription_rejected: "A solicitação de saque foi rejeitada",

    // SHIFT
    name: "Nome",
    startedAt: "Começou às",
    finishedAt: "Terminou em",
    deviceParingCode: "Código de pareamento do dispositivo",
    noShift: "Sem turno",

    // COMPANIES
    confirmDeleteCompany: "Tem certeza que deseja excluir esta empresa?",
    delete: "Excluir",
    companyName: "Nome da empresa",
    createdAt: "Criado em",
    edit: "Editar",
    noCompanies: "Nenhuma empresa",
    updateCompany: "Atualizar empresa",
    createCompany: "Criar empresa",
    saveChanges: "Salvar alterações",

    // USERS
    confirmDeleteUser: "Tem certeza que deseja excluir este usuário?",
    noUsers: "Nenhum usuário",
    updateUser: "Atualizar usuário",
    createUser: "Criar usuário",
    noCompanyAssigned: "Nenhuma empresa atribuída",
    reset: "Reiniciar",
    suspended: "Suspenso",
    yes: "Sim",
    assignedToNumberOfCompanies: "Atribuído a {{number}} empresas",
    selectCompanies: "Selecione empresas",

    // DEVICES
    confirmDeleteDevice: "Tem certeza de que deseja excluir este dispositivo?",
    POSDevices: "Dispositivos POS",
    paringCode: "Código de emparelhamento",
    user: "Do utilizador",
    printPurchase: "Compra impressa",
    receiveTip: "Receber gorjeta",
    noPOSDevices: "Nenhum dispositivo POS",
    updateDevice: "Atualizar dispositivo",
    createDevice: "Criar dispositivo",
    noUserAssigned: "Nenhum usuário atribuído",
    marketType: "Tipo de mercado",

    // PROFILE
    generalInformation: "Informações gerais",
    birthday: "Aniversário",
    phone: "Telefone",
    country: "País",
    avatar: "Imagem de perfil",
    selectFile: "Selecione o arquivo",
    save: "Salvar",
    security: "Segurança",
    "2fa": "Autenticação de dois fatores",
    "2faDescription":
        "A autenticação de dois fatores (2FA) é um método de segurança usado para proteger sua conta www.rodpay.com. O principal objetivo da autenticação de dois fatores é adicionar uma camada adicional de segurança além da senha tradicional. Ao utilizar o e-mail como segundo fator de autenticação, mesmo que alguém consiga obter sua senha, não conseguirá acessar sua conta sem ter acesso ao seu e-mail para obter o código.",
    allow2fa: "Ative a autenticação de dois fatores (2FA)",
    setup2FASuccess: "A configuração da autenticação de dois fatores (2FA) foi bem-sucedida",
    confirmDisable2fa: "Tem certeza de que deseja desativar a autenticação de dois fatores (2FA)?",
    confirm: "Confirme",
    disable2fa: "Desative a autenticação de dois fatores (2FA)",
    inputCode: "Código de entrada",
    "2faSetupGuide":
        "Leia o código QR abaixo usando seu aplicativo autenticador, como o Google Authenticator, e insira o código.",
    customerInformation: "Informação ao Cliente",
    nationalID: "Identidade nacional",
    companyDUNS: "Empresa DUNS",
    selectFileByClickOrDragFileHereToUpload:
        "Selecione o arquivo clicando ou arrastando o arquivo aqui para fazer upload",
    upload: "Carregar",
    nationalIDNeededDescription: "Para sacar mais de 1.000.000 SATs, você precisa verificar sua identidade nacional",
    companyDUNSNeededDescription: "Para sacar mais de 10.000.000 SATs, você precisa verificar o DUNS da sua empresa",
    nationalIDIsUploaded: "O ID nacional foi carregado",
    companyDUNSIsUploaded: "A empresa DUNS foi carregada",
    inReview: "Em revisão",
    approved: "Aprovado",
    rejected: "Rejeitado",
    notificationConfiguration: "Configuração de notificação",
    sendTransactionEmail: "Enviar e-mail de transação",

    // INTEGRATION
    integration: "Integração",
    apiKey: "Chave API",
    apiKeyDescription:
        "Para integrar a API RodPay ao seu próprio serviço, você precisa gerar uma chave de API e usá-la na sua solicitação de API.",
    generateApiKey: "Gerar chave de API",
    apiKeyGeneratedTip: "Esta chave de API é visível apenas uma vez, salve-a em um local seguro.",
    copyToClipboard: "Copiar para área de transferência",
    apiKeySaved: "Chave de API salva",
    copiedToClipboard: "Chave de API copiada para a área de transferência",
    apiKeySavedTip: "Você já salvou sua chave API, pode gerar uma nova ou revogá-la.",
    generateNewApiKey: "Gerar nova chave de API",
    revokeApiKey: "Revogar chave de API",
    confirmRefreshApiKey: "Tem certeza de que deseja atualizar a chave de API?",
    confirmRevokeApiKey: "Tem certeza de revogar a chave de API?",
    revoke: "Revogar",

    // USER LOG
    actionLogs: "Registros de ação",
    action: "Ação",
    model: "Modelo",
    detail: "Detalhe",
    user_action_create: "Criar",
    user_action_update: "Atualizar",
    user_action_delete: "Excluir",
    user_action_soft_delete: "Excluir",
    user_action_restore: "Restaurar",
    user_action_reset_password: "Redefinir senha",
    user_action_tfa_enable: "Habilitar 2FA",
    user_action_tfa_disable: "Desativar 2FA",
    user_action_update_profile: "Atualizar perfil",
    user_action_invoice_paid: "Fatura paga",
    user_action_invoice_expired: "Fatura expirou",
    user_action_withdrawal_in_review: "Revisar retirada",
    user_action_withdrawal_in_process: "Prosseguir com a retirada",
    user_action_withdrawal_finished: "Concluir retirada",
    user_action_withdrawal_rejected: "Rejeitar retirada",
    user_action_api_key_generated: "Chave de API gerada",
    user_action_api_key_revoked: "Chave de API revogada",

    // WITHDRAWAL ACCOUNTS
    bankAccounts: "Contas bancárias",
    bitcoinAccounts: "Contas de bitcoin",
    confirmDeleteBankAccount: "Tem certeza de que deseja excluir esta conta bancária?",
    noBankAccountAdded: "Nenhuma conta bancária adicionada",
    confirmDeleteBitcoinAccount: "Tem certeza de que deseja excluir esta conta bitcoin?",
    noBitcoinAccountAdded: "Nenhuma conta bitcoin adicionada",
    updateBankAccountInfo: "Atualizar informações da conta bancária",
    addNewBankAccount: "Adicionar nova conta bancária",
    nickname: "Apelido",
    bankName: "Nome do banco",
    swiftCode: "Código Swift",
    accountNumber: "Número de conta",
    accountName: "Nome da conta",
    address: "Endereço",
    zipCode: "CEP",
    updateBitcoinAccountInfo: "Atualizar informações da conta bitcoin",
    addNewBitcoinAccount: "Adicionar nova conta bitcoin",
    lightningAddresses: "Endereços Lightning",
    addNewLightningAddress: "Adicionar novo endereço Lightning",
    confirmDeleteLightningAddress: "Tem certeza de que deseja excluir este endereço Lightning?",
    noLightningAddressAdded: "Nenhum endereço Lightning adicionado",
    isPrimary: "É primário",

    // WITHDRAWAL
    firstWithdrawalAmountLimitExceeded:
        "Limite de valor de retirada excedido. O valor do primeiro saque deve ser inferior a 5.000 SATs.",
    secondWithdrawalTimeLimitExceeded:
        "Limite de tempo de retirada excedido. O segundo saque poderá ser feito 24 horas após o primeiro saque.",
    oneDayWithdrawalAmountLimitExceeded:
        "Limite de valor de retirada excedido. O valor da retirada diária deve ser inferior a 1.000.000 SATs.",
    withdrawalKYCNationalIDRequired:
        "O valor total do saque excede o limite (1.000.000 SATs). Verifique sua identidade nacional para continuar.",
    withdrawalKYCCompanyDUNSRequired:
        "O valor total de retirada excede o limite (10.000.000 SATs). Verifique o DUNS da sua empresa para continuar.",
    lightningInvoice: "Lightning fatura",

    // PAYMENT PAGE
    waitingForPayment: "À espera de pagamento...",
    invoiceQRCodeTip: "Digitalize o código QR para pagar a fatura ou clique para copiar o URL da fatura.",
    invoiceURLCopied: "URL da fatura copiado para a área de transferência.",
    invoiceExpired: "A fatura expirou!",
    paymentSucceeded: "Pagamento realizado com sucesso!",
    redirecting: "Redirecionando...",
};
