export const TRANSLATIONS_ZH = {
    // COMMON
    fieldRequired: "此字段是必需的",
    fieldInvalid: "该字段无效",
    fieldMaxLimit: "需要少于 {{limit}} 个字符",
    fieldMaxValueLimit: "值应小于 {{limit}}",
    fieldMinValueLimit: "值应大于 {{limit}}",
    fieldEmailDuplicated: "电子邮件重复",
    fieldConfirmPasswordCorrectly: "确认密码正确",

    success: "成功",
    warning: "警告",
    error: "错误",

    noPermission: "你没有权限！",
    URLNotFound: "找不到网址",
    badRequest: "错误的请求",
    apiError: "API错误",

    // HEADER
    lightningAddressWarning: "请添加至少一个<a href='/profile?_tab=lightning'>Lightning 地址</a>来接收付款",
    myWallet: "我的钱包",
    myProfile: "我的简历",
    withdrawalAccounts: "提款账户",
    signOut: "登出",

    // AUTH LAYOUT
    allRightReserved: "版权所有。",

    // LOGIN
    email: "电子邮件",
    password: "密码",
    keepMeSignedIn: "保持登录状态",
    signIn: "登录",
    forgetPassword: "忘记密码",
    stillHaveNoAccount: "还没有帐户？",
    signUp: "注册",
    getCodeFromYourEmail: "从您的电子邮件中获取代码",
    getCodeFromYourAuthenticatorApp: "从您的身份验证器应用程序获取代码",
    enterCodeFromYourEmail: "输入您电子邮件中的代码",
    accountSuspendedError: "此帐户已暂停，请联系支持人员 <a href='mailto:{{email}}' target='_blank'>{{email}}</a>",
    notAuthenticated: "您尚未通过身份验证，请重新登录！",

    // REGISTER
    confirmPassword: "确认密码",
    fullname: "全名",
    alreadyHaveAnAccount: "已经有帐户？",
    passwordRule: "需要 8 个字符和至少 1 个特殊字符(!@#$&*)",

    // FORGET PASSWORD
    code: "验证码",
    sendCode: "发送验证码",
    resetPassword: "重设密码",
    resendCode: "重新发送验证码",
    backToSignIn: "返回登录",
    verificationCodeSentSuccessfully: "验证码发送成功",

    // VERIFY ACCOUNT
    verify: "核实",
    verify_email: "验证邮件",
    verify_phone: "验证电话",
    verifyDescriptionEmail: "为了验证您的帐户，我们已将验证码发送至 <b class='color-blue'>{{email}}</b>",
    verifyDescriptionPhone: "为了验证您的帐户，我们已向 <b class='color-blue'>+{{phone}}</b> 发送了第二个验证码",
    verifySucceeded: "您的帐户已成功验证。",

    // SIDER
    dashboard: "仪表板",
    transactions: "交易",
    withdrawals: "提款",
    shift: "转移",
    companies: "公司",
    users: "用户",
    pos: "POS",

    // DASHBOARD
    total: "总",
    wallet: "钱包",
    incoming: "传入",
    withdrawal: "退出",
    consumption: "消耗",
    monthly: "每月",
    daily: "每日",

    // INVOICES
    invoices: "发票",
    newInvoice: "新发票",
    expireAt: "到期时间",
    localCurrency: "当地货币",
    amountReceived: "收到金额",
    paidAt: "支付于",
    copyInvoiceUrl: "复制发票网址",
    refresh: "刷新",
    noInvoice: "无发票",
    payLightningInvoice: "支付闪电发票",
    pay: "支付",
    createInvoice: "创建发票",
    invoiceDetail: "发票详细信息",
    invoiceIsExpired: "发票已过期",
    invoiceIsPaid: "发票已支付",
    awaitingPayment: "等待付款",
    close: "关闭",
    copy: "复制",

    // TRANSACTIONS
    downloadAsExcel: "下载为 Excel",
    searchByDescription: "按描述搜索",
    selectPOSDevice: "选择 POS 设备",
    all: "全部",
    startDate: "开始日期",
    finishDate: "完成日期",
    date: "日期",
    company: "公司",
    device: "设备",
    description: "描述",
    amount: "数量",
    tip: "小费",
    currency: "货币",
    commission: "佣金",
    noTransaction: "没有交易",

    // WITHDRAWALS
    withdrawalToBitcoin: "提现到Bitcoin",
    withdrawalToLightningAddress: "提现至 Lightning 地址",
    withdrawalWithLightningInvoice: "使用 Lightning 发票提款",
    lightningAddress: "Lightning 地址",
    selectLightningAddress: "选择 Lightning 地址",
    withdrawalAmountLimitTip:
        "我们提醒您，为了您的安全，新账户的首次提款不能超过 {{firstAmount}} SAT。 此新帐户中的第二次提款可在 24 小时后进行，每日最高金额为 {{dailyAmount}} SAT。",
    lightningWithdrawalInvoiceTip:
        "此提款请求将由管理员审核并批准，这需要一些时间，因此请在创建发票时设置足够的发票有效期。",
    request: "要求",
    toBankAccount: "至银行账户",
    toBitcoinAccount: "至比特币账户",
    requestWithdrawal: "请求提款",
    account: "帐户",
    status: "状态",
    manager: "经理",
    bank: "银行",
    bitcoin: "比特币",
    noWithdrawal: "不提款",
    requestWithdrawalToBank: "向银行请求提款",
    bankAccount: "银行账户",
    selectBankAccount: "选择银行账户",
    shortDescriptionAboutWithdrawal: "关于提款的简短说明",
    cancel: "取消",
    create: "创造",
    requestWithdrawalToBitcoin: "请求提现至比特币",
    bitcoinAccount: "比特币账户",
    selectBitcoinAccount: "选择比特币账户",
    balanceNotEnoughError: "您的钱包余额不足以完成付款",
    withdrawalToLightningAccount: "提现至闪电账户",
    invoiceUrl: "发票网址",
    expiry: "到期",
    seconds: "秒数",
    proceedWithdrawal: "继续提款",
    withdrawalStatus_initiated: "发起",
    withdrawalStatus_in_review: "审核中",
    withdrawalStatus_in_process: "进行中",
    withdrawalStatus_finished: "完成的",
    withdrawalStatus_rejected: "拒绝",
    withdrawalStatusDescription_initiated: "提款请求已创建，等待经理审核",
    withdrawalStatusDescription_in_review: "提款请求正在由经理审核中",
    withdrawalStatusDescription_in_process: "提款请求正在处理中",
    withdrawalStatusDescription_finished: "提款流程已完成",
    withdrawalStatusDescription_rejected: "提款请求被拒绝",

    // SHIFT
    name: "姓名",
    startedAt: "开始于",
    finishedAt: "完成于",
    deviceParingCode: "设备配对码",
    noShift: "无班次",

    // COMPANIES
    confirmDeleteCompany: "您确定删除该公司吗？",
    delete: "删除",
    companyName: "公司名称",
    createdAt: "创建于",
    edit: "编辑",
    noCompanies: "没有公司",
    updateCompany: "更新公司",
    createCompany: "创建公司",
    saveChanges: "保存更改",

    // USERS
    confirmDeleteUser: "您确定删除该用户吗？",
    noUsers: "没有用户",
    updateUser: "更新用户",
    createUser: "创建用户",
    noCompanyAssigned: "没有指定公司",
    reset: "重置",
    suspended: "暂停",
    yes: "是",
    assignedToNumberOfCompanies: "分配给 {{number}} 公司",
    selectCompanies: "选择公司",

    // DEVICES
    confirmDeleteDevice: "您确定要删除该设备吗？",
    POSDevices: "POS 设备",
    paringCode: "配对码",
    user: "用户",
    printPurchase: "打印购买",
    receiveTip: "收到小费",
    noPOSDevices: "没有 POS 设备",
    updateDevice: "更新设备",
    createDevice: "创建设备",
    noUserAssigned: "未分配用户",
    marketType: "市场类型",

    // PROFILE
    generalInformation: "一般信息",
    birthday: "生日",
    phone: "电话",
    country: "国家",
    avatar: "头像",
    selectFile: "选择文件",
    save: "保存",
    security: "安全",
    "2fa": "两因素身份验证",
    "2faDescription":
        "双因素身份验证 (2FA) 是一种用于保护您的 www.rodpay.com 帐户的安全方法。 双因素身份验证的主要目标是在传统密码之外添加额外的安全层。 通过使用电子邮件作为身份验证的第二个因素，即使有人设法获取您的密码，如果他们无法访问您的电子邮件以获取代码，他们也将无法访问您的帐户。",
    allow2fa: "激活双因素身份验证 (2FA)",
    setup2FASuccess: "双因素身份验证 (2FA) 设置成功",
    confirmDisable2fa: "您确定禁用双因素身份验证 (2FA) 吗？",
    confirm: "确认",
    disable2fa: "禁用双因素身份验证 (2FA)",
    inputCode: "输入代码",
    "2faSetupGuide": "请使用您的身份验证应用程序（例如 Google Authenticator）扫描下面的二维码，然后输入代码。",
    customerInformation: "客户信息",
    nationalID: "国民身份证",
    companyDUNS: "公司邓白氏编码",
    selectFileByClickOrDragFileHereToUpload: "单击选择文件或将文件拖至此处上传",
    upload: "上传",
    nationalIDNeededDescription: "要提取超过 1,000,000 SAT，您需要验证您的国民身份证",
    companyDUNSNeededDescription: "要提取超过 10,000,000 SAT，您需要验证您的公司 DUNS",
    nationalIDIsUploaded: "国民身份证已上传",
    companyDUNSIsUploaded: "公司 DUNS 已上传",
    inReview: "审核中",
    approved: "得到正式认可的",
    rejected: "拒绝",
    notificationConfiguration: "通知配置",
    sendTransactionEmail: "发送交易邮件",

    // INTEGRATION
    integration: "一体化",
    apiKey: "API密钥",
    apiKeyDescription: "要将 RodPay API 集成到您自己的服务中，您需要生成一个 API 密钥并在您的 API 请求中使用它。",
    generateApiKey: "生成API密钥",
    apiKeyGeneratedTip: "此 API 密钥仅可见一次，请将其保存在安全的地方。",
    copyToClipboard: "复制到剪贴板",
    apiKeySaved: "API 密钥已保存",
    copiedToClipboard: "API 密钥已复制到剪贴板",
    apiKeySavedTip: "您已经保存了 API 密钥，您可以生成新的或撤销它。",
    generateNewApiKey: "生成新的 API 密钥",
    revokeApiKey: "撤销 API 密钥",
    confirmRefreshApiKey: "您确定刷新 API 密钥吗？",
    confirmRevokeApiKey: "您确定要撤销API Key吗？",
    revoke: "撤销",

    // USER LOG
    actionLogs: "行动日志",
    action: "行动",
    model: "模型",
    detail: "细节",
    user_action_create: "创造",
    user_action_update: "更新",
    user_action_delete: "删除",
    user_action_soft_delete: "删除",
    user_action_restore: "恢复",
    user_action_reset_password: "重设密码",
    user_action_tfa_enable: "启用 2FA",
    user_action_tfa_disable: "禁用 2FA",
    user_action_update_profile: "更新个人信息",
    user_action_invoice_paid: "发票已付",
    user_action_invoice_expired: "发票已过期",
    user_action_withdrawal_in_review: "审查撤回",
    user_action_withdrawal_in_process: "继续提款",
    user_action_withdrawal_finished: "完成提现",
    user_action_withdrawal_rejected: "拒绝提款",
    user_action_api_key_generated: "已生成 API 密钥",
    user_action_api_key_revoked: "API 密钥已撤销",

    // WITHDRAWAL ACCOUNTS
    bankAccounts: "银行账户",
    bitcoinAccounts: "比特币账户",
    confirmDeleteBankAccount: "您确定删除该银行帐户吗？",
    noBankAccountAdded: "未添加银行账户",
    confirmDeleteBitcoinAccount: "您确定删除该比特币账户吗？",
    noBitcoinAccountAdded: "未添加比特币账户",
    updateBankAccountInfo: "更新银行账户信息",
    addNewBankAccount: "添加新银行账户",
    nickname: "昵称",
    bankName: "银行名",
    swiftCode: "SWIFT代码",
    accountNumber: "帐号",
    accountName: "帐户名称",
    address: "地址",
    zipCode: "邮政编码",
    updateBitcoinAccountInfo: "更新比特币账户信息",
    addNewBitcoinAccount: "添加新的比特币账户",
    lightningAddresses: "Lightning 地址",
    addNewLightningAddress: "添加新的 Lightning 地址",
    confirmDeleteLightningAddress: "您确定要删除此Lightning地址吗？",
    noLightningAddressAdded: "未添加 Lightning 地址",
    isPrimary: "是主要的",

    // WITHDRAWAL
    firstWithdrawalAmountLimitExceeded: "超出提现金额限制。 首次提现金额应小于5,000 SAT。",
    secondWithdrawalTimeLimitExceeded: "超过提现时间限制。 第一次提款后24小时后即可进行第二次提款。",
    oneDayWithdrawalAmountLimitExceeded: "超出提现金额限制。 每日提现金额不得超过1,000,000 SAT。",
    withdrawalKYCNationalIDRequired: "您的提款总额超出限额（1,000,000 SAT），请验证您的身份证件以继续。",
    withdrawalKYCCompanyDUNSRequired: "您的提款总额超出限制（10,000,000 SAT），请验证您的公司邓白氏以继续。",
    lightningInvoice: "Lightning 发票",

    // PAYMENT PAGE
    waitingForPayment: "等待付款...",
    invoiceQRCodeTip: "扫描二维码支付发票或点击复制发票网址。",
    invoiceURLCopied: "发票 URL 已复制到剪贴板。",
    invoiceExpired: "发票过期了！",
    paymentSucceeded: "付款成功！",
    redirecting: "正在重定向...",
};
