/* eslint-disable max-len */
export const TRANSLATIONS_IT = {
    // COMMON
    fieldRequired: "Questo campo è obbligatorio",
    fieldInvalid: "Questo campo non è valido",
    fieldMaxLimit: "Sono richiesti meno di {{limit}} caratteri",
    fieldMaxValueLimit: "Il valore dovrebbe essere minore di {{limit}}",
    fieldMinValueLimit: "Il valore dovrebbe essere maggiore di {{limit}}",
    fieldEmailDuplicated: "Email duplicata",
    fieldConfirmPasswordCorrectly: "Conferma la password correttamente",

    success: "Successo",
    warning: "Avvertimento",
    error: "Errore",

    noPermission: "Non hai il permesso!",
    URLNotFound: "URL non trovato",
    badRequest: "Brutta richiesta",
    apiError: "Errore dell'API",

    // HEADER
    lightningAddressWarning:
        "Aggiungi almeno un <a href='/profile?_tab=lightning'>indirizzo Lightning</a> per ricevere i pagamenti",
    myWallet: "Il mio portafoglio",
    myProfile: "Il mio profilo",
    withdrawalAccounts: "Conti di prelievo",
    signOut: "Disconnessione",

    // AUTH LAYOUT
    allRightReserved: "Tutti i diritti riservati.",

    // LOGIN
    email: "E-mail",
    password: "Parola d'ordine",
    keepMeSignedIn: "Tienimi connesso",
    signIn: "Registrazione",
    forgetPassword: "Dimenticare la password",
    stillHaveNoAccount: "Non hai ancora un account?",
    signUp: "Iscrizione",
    getCodeFromYourEmail: "Ottieni il codice dalla tua email",
    getCodeFromYourAuthenticatorApp: "Ottieni il codice dalla tua app di autenticazione",
    enterCodeFromYourEmail: "Inserisci il codice dalla tua email",
    accountSuspendedError:
        "Questo account è sospeso, contatta l'assistenza a <a href='mailto:{{email}}' target='_blank'>{{email}}</a>",
    notAuthenticated: "Non sei autenticato, effettua nuovamente il login!",

    // REGISTER
    confirmPassword: "Conferma password",
    fullname: "Nome e cognome",
    alreadyHaveAnAccount: "Hai già un account?",
    passwordRule: "Sono richiesti 8 caratteri e almeno 1 carattere speciale (!@#$&*).",

    // FORGET PASSWORD
    code: "Codice",
    sendCode: "Manda il codice",
    resetPassword: "Resetta la password",
    resendCode: "Codice di rispedizione",
    backToSignIn: "Torna a Accedi",
    verificationCodeSentSuccessfully: "Il codice di verifica è stato inviato con successo",

    // VERIFY ACCOUNT
    verify: "Verificare",
    verify_email: "Verifica Email",
    verify_phone: "Verifica telefono",
    verifyDescriptionEmail:
        "Per convalidare il tuo account, abbiamo inviato un codice di verifica a <b class='color-blue'>{{email}}</b>",
    verifyDescriptionPhone:
        "Per convalidare il tuo account, abbiamo inviato un secondo codice di verifica a <b class='color-blue'>+{{phone}}</b>",
    verifySucceeded: "Il tuo account è stato verificato con successo.",

    // SIDER
    dashboard: "Pannello di controllo",
    transactions: "Transazioni",
    withdrawals: "Prelievi",
    shift: "Spostare",
    companies: "Aziende",
    users: "Utenti",
    pos: "POS",

    // DASHBOARD
    total: "Totale",
    wallet: "Portafoglio",
    incoming: "In arrivo",
    withdrawal: "Ritiro",
    consumption: "Consumo",
    monthly: "Mensile",
    daily: "Quotidiano",

    // INVOICES
    invoices: "Fatture",
    newInvoice: "Nuova fattura",
    expireAt: "Scade alle",
    localCurrency: "Moneta locale",
    amountReceived: "Quantità ricevuta",
    paidAt: "Pagato a",
    copyInvoiceUrl: "Copia l'URL della fattura",
    refresh: "Ricaricare",
    noInvoice: "Nessuna fattura",
    payLightningInvoice: "Paga la fattura Lightning",
    pay: "Paga",
    createInvoice: "Crea fattura",
    invoiceDetail: "Dettaglio fattura",
    invoiceIsExpired: "La fattura è scaduta",
    invoiceIsPaid: "La fattura è pagata",
    awaitingPayment: "In attesa del pagamento",
    close: "Vicino",
    copy: "Copia",

    // TRANSACTIONS
    downloadAsExcel: "Scarica come Excel",
    searchByDescription: "Cerca per descrizione",
    selectPOSDevice: "Seleziona il dispositivo POS",
    all: "Tutto",
    startDate: "Data d'inizio",
    finishDate: "Data di fine",
    date: "Data",
    company: "Azienda",
    device: "Dispositivo",
    description: "Descrizione",
    amount: "Quantità",
    tip: "Mancia",
    currency: "Valuta",
    commission: "Commissione",
    noTransaction: "Nessuna transazione",

    // WITHDRAWALS
    withdrawalToBitcoin: "Prelievo su Bitcoin",
    withdrawalToLightningAddress: "Prelievo all'indirizzo Lightning",
    withdrawalWithLightningInvoice: "Prelievo con fattura Lightning",
    lightningAddress: "Lightning Indirizzo",
    selectLightningAddress: "Seleziona l'indirizzo Lightning",
    withdrawalAmountLimitTip:
        "Ti ricordiamo che, per la tua sicurezza, il primo prelievo su un nuovo conto non può superare i {{firstAmount}} SAT. Il secondo prelievo su questo nuovo conto può essere effettuato dopo 24 ore e per un importo massimo giornaliero di {{dailyAmount}} SAT.",
    lightningWithdrawalInvoiceTip:
        "Questa richiesta di prelievo verrà esaminata e approvata dall'amministratore, ci vorrà del tempo, quindi imposta una scadenza della fattura sufficiente quando crei una fattura.",
    request: "Richiesta",
    toBankAccount: "Sul conto bancario",
    toBitcoinAccount: "Al conto bitcoin",
    requestWithdrawal: "Richiedi il ritiro",
    account: "Account",
    status: "Stato",
    manager: "Manager",
    bank: "Banca",
    bitcoin: "Bitcoin",
    noWithdrawal: "Nessun ritiro",
    requestWithdrawalToBank: "Richiedi il prelievo alla banca",
    bankAccount: "Conto bancario",
    selectBankAccount: "Seleziona conto bancario",
    shortDescriptionAboutWithdrawal: "Breve descrizione del recesso",
    cancel: "Annulla",
    create: "Creare",
    requestWithdrawalToBitcoin: "Richiedi il prelievo in bitcoin",
    bitcoinAccount: "Conto Bitcoin",
    selectBitcoinAccount: "Seleziona l'account bitcoin",
    balanceNotEnoughError: "Il saldo del tuo portafoglio non è sufficiente per completare il pagamento",
    withdrawalToLightningAccount: "Prelievo su conto fulmine",
    invoiceUrl: "URL della fattura",
    expiry: "Scadenza",
    seconds: "Secondi",
    proceedWithdrawal: "Procedere al ritiro",
    withdrawalStatus_initiated: "Avviato",
    withdrawalStatus_in_review: "In revisione",
    withdrawalStatus_in_process: "In corso",
    withdrawalStatus_finished: "Finito",
    withdrawalStatus_rejected: "Respinto",
    withdrawalStatusDescription_initiated:
        "Viene creata la richiesta di prelievo, in attesa della revisione da parte del gestore",
    withdrawalStatusDescription_in_review: "La richiesta di prelievo è in fase di revisione da parte del gestore",
    withdrawalStatusDescription_in_process: "La richiesta di prelievo è in corso",
    withdrawalStatusDescription_finished: "Il processo di prelievo è terminato",
    withdrawalStatusDescription_rejected: "La richiesta di prelievo viene respinta",

    // SHIFT
    name: "Nome",
    startedAt: "Iniziato alle",
    finishedAt: "Finito alle",
    deviceParingCode: "Codice di associazione del dispositivo",
    noShift: "Nessun turno",

    // COMPANIES
    confirmDeleteCompany: "Sei sicuro di eliminare questa azienda?",
    delete: "Eliminare",
    companyName: "Nome della ditta",
    createdAt: "Creato a",
    edit: "Modificare",
    noCompanies: "Nessuna compagnia",
    updateCompany: "Aggiorna azienda",
    createCompany: "Crea compagnia",
    saveChanges: "Salvare le modifiche",

    // USERS
    confirmDeleteUser: "Sei sicuro di eliminare questo utente?",
    noUsers: "Nessun utente",
    updateUser: "Aggiorna utente",
    createUser: "Creare un utente",
    noCompanyAssigned: "Nessuna azienda assegnata",
    reset: "Ripristina",
    suspended: "Sospeso",
    yes: "SÌ",
    assignedToNumberOfCompanies: "Assegnato a {{number}} aziende",
    selectCompanies: "Seleziona aziende",

    // DEVICES
    confirmDeleteDevice: "Sei sicuro di eliminare questo dispositivo?",
    POSDevices: "Dispositivi POS",
    paringCode: "Codice di associazione",
    user: "Utente",
    printPurchase: "Stampa acquisto",
    receiveTip: "Ricevi mancia",
    noPOSDevices: "Nessun dispositivo POS",
    updateDevice: "Aggiorna dispositivo",
    createDevice: "Crea dispositivo",
    noUserAssigned: "Nessun utente assegnato",
    marketType: "Tipo di mercato",

    // PROFILE
    generalInformation: "Informazioni generali",
    birthday: "Compleanno",
    phone: "Telefono",
    country: "Paese",
    avatar: "Immagine del profilo",
    selectFile: "Seleziona il file",
    save: "Salva",
    security: "Sicurezza",
    "2fa": "Autenticazione a due fattori",
    "2faDescription":
        "L'autenticazione a due fattori (2FA) è un metodo di sicurezza utilizzato per proteggere il tuo account www.rodpay.com. L'obiettivo principale dell'autenticazione a due fattori è aggiungere un ulteriore livello di sicurezza oltre alla password tradizionale. Utilizzando l'e-mail come secondo fattore di autenticazione, anche se qualcuno riesce a ottenere la tua password, non potrà accedere al tuo account senza avere accesso alla tua e-mail per ottenere il codice.",
    allow2fa: "Attiva l'autenticazione a due fattori (2FA)",
    setup2FASuccess: "L'impostazione dell'autenticazione a due fattori (2FA) è riuscita",
    confirmDisable2fa: "Sei sicuro di disabilitare l'autenticazione a due fattori (2FA)?",
    confirm: "Confermare",
    disable2fa: "Disattiva l'autenticazione a due fattori (2FA)",
    inputCode: "Inserisci il codice",
    "2faSetupGuide":
        "Esegui la scansione del codice QR di seguito utilizzando l'app di autenticazione come Google Authenticator e inserisci il codice.",
    customerInformation: "Informazioni per il cliente",
    nationalID: "Carta d'identità nazionale",
    companyDUNS: "Azienda DUNS",
    selectFileByClickOrDragFileHereToUpload: "Seleziona il file facendo clic o trascina il file qui per caricarlo",
    upload: "Caricamento",
    nationalIDNeededDescription:
        "Per prelevare più di 1.000.000 di SAT, è necessario verificare la propria carta d'identità nazionale",
    companyDUNSNeededDescription:
        "Per prelevare più di 10.000.000 di SAT è necessario verificare la propria Azienda DUNS",
    nationalIDIsUploaded: "L'ID nazionale è caricato",
    companyDUNSIsUploaded: "La società DUNS è caricata",
    inReview: "In revisione",
    approved: "Approvato",
    rejected: "Respinto",
    notificationConfiguration: "Configurazione delle notifiche",
    sendTransactionEmail: "Invia email di transazione",

    // INTEGRATION
    integration: "Integrazione",
    apiKey: "Chiave API",
    apiKeyDescription:
        "Per integrare l'API RodPay nel tuo servizio, devi generare una chiave API e utilizzarla nella tua richiesta API.",
    generateApiKey: "Genera chiave API",
    apiKeyGeneratedTip: "Questa chiave API è visibile solo una volta, salvala in un luogo sicuro.",
    copyToClipboard: "Copia negli appunti",
    apiKeySaved: "Chiave API salvata",
    copiedToClipboard: "Chiave API copiata negli appunti",
    apiKeySavedTip: "Hai già salvato la tua chiave API, puoi generarne una nuova o revocarla.",
    generateNewApiKey: "Genera una nuova chiave API",
    revokeApiKey: "Revoca la chiave API",
    confirmRefreshApiKey: "Vuoi aggiornare la chiave API?",
    confirmRevokeApiKey: "Sei sicuro di voler revocare la chiave API?",
    revoke: "Revocare",

    // USER LOG
    actionLogs: "Registri delle azioni",
    action: "Azione",
    model: "Modello",
    detail: "Dettaglio",
    user_action_create: "Creare",
    user_action_update: "Aggiornamento",
    user_action_delete: "Eliminare",
    user_action_soft_delete: "Eliminare",
    user_action_restore: "Ristabilire",
    user_action_reset_password: "Resetta la password",
    user_action_tfa_enable: "Abilita 2FA",
    user_action_tfa_disable: "Disabilita 2FA",
    user_action_update_profile: "Aggiorna il profilo",
    user_action_invoice_paid: "Fattura pagata",
    user_action_invoice_expired: "Fattura scaduta",
    user_action_withdrawal_in_review: "Revisione del ritiro",
    user_action_withdrawal_in_process: "Procedere al ritiro",
    user_action_withdrawal_finished: "Termina il ritiro",
    user_action_withdrawal_rejected: "Rifiutare il ritiro",
    user_action_api_key_generated: "Chiave API generata",
    user_action_api_key_revoked: "Chiave API revocata",

    // WITHDRAWAL ACCOUNTS
    bankAccounts: "Conto in banca",
    bitcoinAccounts: "Conti Bitcoin",
    confirmDeleteBankAccount: "Sei sicuro di eliminare questo conto bancario?",
    noBankAccountAdded: "Nessun conto bancario aggiunto",
    confirmDeleteBitcoinAccount: "Sei sicuro di eliminare questo account bitcoin?",
    noBitcoinAccountAdded: "Nessun account bitcoin aggiunto",
    updateBankAccountInfo: "Aggiorna i dati del conto bancario",
    addNewBankAccount: "Aggiungi nuovo conto bancario",
    nickname: "Soprannome",
    bankName: "nome della banca",
    swiftCode: "Codice SWIFT",
    accountNumber: "Numero di conto",
    accountName: "Nome utente",
    address: "Indirizzo",
    zipCode: "Cap",
    updateBitcoinAccountInfo: "Aggiorna le informazioni sull'account bitcoin",
    addNewBitcoinAccount: "Aggiungi un nuovo account bitcoin",
    lightningAddresses: "Lightning Indirizzi",
    addNewLightningAddress: "Aggiungi nuovo indirizzo Lightning",
    confirmDeleteLightningAddress: "Vuoi davvero eliminare questo indirizzo Lightning?",
    noLightningAddressAdded: "Nessun indirizzo Lightning aggiunto",
    isPrimary: "È primario",

    // WITHDRAWAL
    firstWithdrawalAmountLimitExceeded:
        "Limite dell'importo di prelievo superato. L'importo del primo prelievo deve essere inferiore a 5.000 SAT.",
    secondWithdrawalTimeLimitExceeded:
        "Limite del tempo di prelievo superato. Il secondo prelievo potrà essere effettuato dopo 24 ore dal primo prelievo.",
    oneDayWithdrawalAmountLimitExceeded:
        "Limite dell'importo di prelievo superato. L'importo del prelievo giornaliero deve essere inferiore a 1.000.000 di SAT.",
    withdrawalKYCNationalIDRequired:
        "L'importo totale del prelievo supera il limite (1.000.000 di SAT), verifica il tuo documento d'identità nazionale per continuare.",
    withdrawalKYCCompanyDUNSRequired:
        "L'importo totale del prelievo supera il limite (10.000.000 SAT), verifica il DUNS della tua azienda per continuare.",
    lightningInvoice: "Fattura Lightning",

    // PAYMENT PAGE
    waitingForPayment: "Aspettando il pagamento...",
    invoiceQRCodeTip: "Scansiona il codice QR per pagare la fattura o fai clic per copiare l'URL della fattura.",
    invoiceURLCopied: "URL della fattura copiato negli appunti.",
    invoiceExpired: "Fattura scaduta!",
    paymentSucceeded: "Pagamento riuscito!",
    redirecting: "Reindirizzamento...",
};
